<template>
  <div class="main-content">
    <avue-crud
      ref="crud"
      :data="tableData"
      :option="option"
      :table-loading="showLoading"
      :page.sync="page"
      @on-load="onLoad"
    >
      <template #header>
        <div>
          <div class="flex flex-wrap align-items marginBottom20">
            <div class="flex align-items marginRight10 marginBottom10">
              <!-- <span class="span marginRight10 width80 textAlignRight"> </span> -->
              <div class="width300">
                <el-input
                  v-model="searchData.className"
                  placeholder="请输入班级名称"
                  clearable
                  maxlength="50"
                ></el-input>
              </div>
            </div>
            <div class="flex align-items marginRight10 marginBottom10">
              <!-- <span class="span marginRight10 width80 textAlignRight"> </span> -->
              <div class="width300">
                <el-input
                  v-model="searchData.fullName"
                  placeholder="请输入班主任姓名"
                  clearable
                  maxlength="50"
                ></el-input>
              </div>
            </div>
            <div class="flex align-items marginRight10 marginBottom10">
              <!-- <span class="span marginRight10 width80 textAlignRight"> </span> -->
              <div class="width300">
                <el-input
                  v-model="searchData.phone"
                  placeholder="请输入班主任电话"
                  clearable
                  maxlength="50"
                ></el-input>
              </div>
            </div>
            <div class="flex align-items marginRight10 marginBottom10">
              <!-- <span class="span marginRight10 width80 textAlignRight"> </span> -->
              <div class="width300">
                <el-select
                  v-model="searchData.levelId"
                  clearable
                  placeholder="学生等级"
                >
                  <el-option
                    v-for="(item, index) in studentList"
                    :key="index"
                    :label="item.levelName"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </div>
            </div>

            <el-button
              size="medium"
              type="primary"
              @click="handleSearch"
              style="margin-bottom: 10px"
              class="marginLeft10"
              >搜索</el-button
            >
            <el-button
              size="medium"
              type="info"
              @click="handleReset"
              style="margin-bottom: 10px"
              class="marginLeft10 marginRight10"
              >重置</el-button
            >
            <div class="flex align-items marginRight10 marginBottom10">
              <el-button size="medium" type="primary" @click="handleRevert"
                >返回</el-button
              >
            </div>
          </div>
        </div>
      </template>
      <template slot="studenNum" slot-scope="{ row }">
        <el-button type="text" @click="handNum(row)">{{
          row.studenNum
        }}</el-button>
      </template>
    </avue-crud>
  </div>
</template>

<script>
import { classReviewPage } from "@/api/user/class";
// import { MChannel, OChannel, EChannel } from "@/enums/beCurrent/EBeCurrent";
export default {
  name: "index",
  components: {
    Table,
  },
  data() {
    return {
      // MChannel: MChannel,
      // OChannel: OChannel,
      // EChannel: EChannel,
      searchData: {
        id: this.$route.query.id,
      },
      page: {
        total: 0,
        currentPage: 1,
      },
      tableData: [],
      showLoading: false,
      showLoadingForm: false,
      dialogVisible: false,
      option: {
        title: "",
        titleSize: "h3",
        card: false,
        border: true,
        header: false,
        stripe: false, // 显示斑马纹
        showHeader: true, // 显示表头
        index: false, // 显示索引
        indexLabel: "序号", // 索引文字
        size: "small",
        page: true, // 显示分页
        align: "center",
        menuAlign: "center",
        menuType: "text",
        editBtn: false,
        delBtn: false,
        selection: false,
        reserveSelection: true,
        selectionFixed: true,
        tip: false,
        column: [
          {
            label: "id",
            prop: "id",
          },
          {
            label: "班级名称",
            prop: "className",
          },
          {
            label: "班主任姓名",
            prop: "fullName",
          },
          {
            label: "联系电话",
            prop: "phone",
          },
          {
            label: "学生等级",
            prop: "levelName",
          },
          {
            label: "学生数量",
            prop: "studenNum",
          },
        ],
      },
      dialogVisible: false,
    };
  },
  methods: {
    onLoad() {
      this.showLoading = true;
      const searchData = {
        ...this.searchData,
      };
      classReviewPage({
        page: this.page.currentPage,
        size: this.page.pageSize,
        ...searchData,
      }).then((res) => {
        this.showLoading = false;
        if (res.code === 200) {
          this.tableData = res.data.records;
          this.page.total = res.data.total;
        }
      });
    },

    // 查询
    handleSearch() {
      // console.log('查询')
      this.page.currentPage = 1;
      this.onLoad();
    },
    // 重置
    handleReset() {
      // console.log('重置')
      this.searchData = {
        state: "",
        name: "",
        createTime: "",
      };
      this.page.currentPage = 1;
      this.onLoad();
    },
    //输入
    salaryDianChange(e, t) {
      const value = e.target.value;
      this.form[t] = value;
    },

    handleRevert() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped></style>
